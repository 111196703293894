import { TabHref } from "@components/BottomFooter"
import { StageSize } from "@features/canvas/slideList/SlideSizeContext"
import { Slide } from "@type/models/Canvas"
import { PostTimelineRead } from "@type/models/Post"

export type SessionStorageKeyValues = {
	postTimelineDataDict: { [key in PostTimelineRead["id"]]: PostTimelineRead }
	currentTab: TabHref
	isTestMode: boolean
	postedCanvasCoursePairs: { canvasId: string; courseId: string }[]
	lastSavedCanvas: {
		canvasId: string
		slideList: Slide[]
		stagePixelSize: StageSize
	}
}
type Keys = keyof SessionStorageKeyValues

export const CCSessionStorage = {
	setItem: <T extends Keys>(key: T, value: SessionStorageKeyValues[T]): void =>
		sessionStorage.setItem(key, JSON.stringify(value)),

	getItem: <T extends Keys, S = undefined>(
		key: T,
		defaultValue: S,
	): SessionStorageKeyValues[T] | S => {
		const item = sessionStorage.getItem(key)
		if (item === null) {
			return defaultValue
		}
		if (item === "undefined") {
			return defaultValue
		}
		try {
			const v = JSON.parse(item)
			return v
		} catch (e) {
			console.error(e)
			return defaultValue
		}
	},

	removeItem: <T extends Keys>(key: T): void => sessionStorage.removeItem(key),

	clear: (): void => sessionStorage.clear(),
}
